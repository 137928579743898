import React, { useState, useContext, useEffect } from "react";
import { FooterPanel } from "../../components/FooterPanel";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { useAuth } from "../../contexts/auth/auth.context";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaCircleMinus, FaCirclePlus } from 'react-icons/fa6'
import { CheckBox } from "../CheckBox/CheckBox";
import AlertContext from '../../contexts/alert/AlertContext';
import ConfirmContext from '../../contexts/confirm/ConfirmContext';
import * as productApi from "../../api/products";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export const ProductBasketList = ({bks}) => {
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(4092);
  const [ totalPrice, setTotalPrice ] = useState(0);
  const [ totalCount, setTotalCount ] = useState(0);
  const [ checkedAll, setCheckedAll ] = useState(false);
  const [ checkItems, setCheckItems ] = useState([]);
  const [ baskets, setBaskets ] = useState([]);
  const { user, setUser, global, setGlobal } = useAuth();
  const { alert: alertComp } = useContext(AlertContext);
  const { confirm: confirmComp } = useContext(ConfirmContext);

  // console.log(`ProductBasketList:start:baskets=${baskets.length}, checkItems=${checkItems.length}`)

  // 모든 항목 선택/해제 체크
  const handleAllCheckItem = (e) => {
    if (checkedAll === false) { 
      setCheckItems(baskets.map((basket) => basket.id));
      setCheckedAll(true);
    } else {
      setCheckItems([]);
      setCheckedAll(false);
    }
    console.log(`allCheck = `, checkedAll)
  }

  // 일괄 선택된 장바구니 상품 구독 신청
  const handleSubscribeSelectedItems = async () => {
    let datas = [];
    baskets.map((basket, index, tot) => {
      if (checkItems.includes(basket.id) === true) {
        const data = {
          basket_id: basket.id,
          product_id: basket.product_id,
          price_id: basket.price_id
        };
        datas.push(data);
      }
    });
    if (datas.length > 0) {
      productApi.postProductSubscribes({data: datas}, { access: user.access }).then(async response => {
        if(response.status == 200 && response.data.code === "success") {
          Swal.fire({ position: 'center', icon: 'success',
            title: '선택된 상품이 구독 신청되었습니다.',
          }).then((result) => {
            if(result.isConfirmed) {
              // 구독된 항목 CheckedItem에서 제거
              setBaskets(baskets.filter((basket) => checkItems.includes(basket.id) === false));
              setCheckItems([])
            }
          }).catch(function(reason) {console.log('fail-'+reason)})
        } else {
          Swal.fire({ position: 'center', icon: 'warning',
            title: response.data.detail,
          }); 
        }
      }).catch(error => {
        Swal.fire({ position: 'center', icon: 'warning',
          title: error.message,
        }); 
      })
    } else {
      Swal.fire({ position: 'center', icon: 'warning',
        title: '선택된 상품이 없습니다.',
      }); 
    }
  }

  // 일괄 선택된 장바구니 상품 삭제
  const handleDeleteSelectedItems = async (e) => {
    if (checkItems.length > 0) {
      let datas = {};
      checkItems.map((item, index, tot) => {
        let key = 'id_' + String(index);
        datas[key] = parseInt(item);
      });
      productApi.deleteProductBasketSelected(datas, { access: user.access }).then(async response => {
        if(response.status == 200 && response.data.code === "success") {
          Swal.fire({ position: 'center', icon: 'success',
            title: '선택된 모든 항목이 장바구니에서 제거되었습니다.',
            confirmButtonColor: '#48088A',
            showConfirmButton: true,
          }).then((result) => {
            if(result.isConfirmed) {
              // 삭제된 항목 CheckedItem에서 제거
              setBaskets(baskets.filter((basket) => checkItems.includes(basket.id) === false));
              setCheckItems([]);
            }
          }).catch(function(reason) {console.log('fail-'+reason)})
        } else {
          Swal.fire({ position: 'center', icon: 'warning',
            title: response.data.detail,
          }); 
        }
      }).catch(error => {
        Swal.fire({ position: 'center', icon: 'warning',
          title: error.message,
        }); 
      })
    } else {
      Swal.fire({ position: 'center', icon: 'warning',
        title: '선택된 상품이 없습니다.',
      }); 
    }
  }

  // 특정 장바구니 상품 구독 신청
  const handleSubscribeItem = async (id, product_id, price_id, e) => {
    let datas = [];
    const indx = baskets.findIndex((basket) => basket.id === id);
    if (checkItems.includes(id) === false && indx >= 0) {
      // baskets[indx].id
      const title = `${baskets[indx].product_name}을 구독하시겠습니까?`;
      const text = `구독료 : ${baskets[indx].final_price.toLocaleString('ko-KR')}원 (${baskets[indx].price_name})`;
      Swal.fire({ position: 'center', icon: 'success',
        title: title, text: text,
        showCancelButton: true,
        confirmButtonText: "구독",
        cancelButtonText: "취소",
      }).then((result) => {
        if(result.isConfirmed) {
          const data = { 
            basket_id: id,
            product_id: product_id,
            price_id: price_id
          };
          datas.push(data);
          applySubscribeProduct(id, datas, indx);
        }
      }).catch(function(reason) {console.log('fail-'+reason)})
    }
    if (checkItems.includes(id) === true) {
      const data = { 
        basket_id: id,
        product_id: product_id,
        price_id: price_id
      };
      datas.push(data);
      applySubscribeProduct(id, datas, indx);
    }
  }
  // 상품 구독 신청
  const applySubscribeProduct = (id, datas, indx) => {
    productApi.postProductSubscribes({data: datas}, { access: user.access }).then(async response => {
      if(response.status == 200 && response.data.code === "success") {
        const title = `선택된 상품이 구독 신청되었습니다.`;
        const text = `${baskets[indx].product_name}\n구독료 : ${baskets[indx].final_price.toLocaleString('ko-KR')}원 (${baskets[indx].price_name})`;
        Swal.fire({ position: 'center', icon: 'success',
          title: title, text: text,
        }).then((result) => {
          if(result.isConfirmed) {
            // 구독된 항목 CheckedItem에서 제거
            setBaskets(baskets.filter((basket) => basket.id !== id));
            setCheckItems(checkItems.filter((item) => item !== id))
          }
        }).catch(function(reason) {console.log('fail-'+reason)})
      } else {
        const result = await alertComp(response.data.detail);
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  // 특정 장바구니 상품 삭제
  const handleDeleteItem = (id, e) => {
    if (checkItems.includes(id) === true) {
      productApi.deleteProductBasket(id, { access: user.access }).then(async response => {
        if(response.status == 200 && response.data.code === "success") {
          Swal.fire({ position: 'center', icon: 'success',
            title: '선택된 항목이 장바구니에서 삭제되었습니다.',
          }).then((result) => {
            if(result.isConfirmed) {
              // 삭제된 항목 CheckedItem에서 제거
              setBaskets(baskets.filter((basket) => basket.id !== id));
              setCheckItems(checkItems.filter((item) => item !== id))
            }
          }).catch(function(reason) {console.log('fail-'+reason)})
        } else {
          Swal.fire({ position: 'center', icon: 'warning',
            title: response.data.detail,
          }); 
        }
      }).catch(error => {
        Swal.fire({ position: 'center', icon: 'warning',
          title: error.message,
        }); 
      })
    }
  }

  // 특정 항목 체크
  const handleCheckItem = (id, isChecked) => {
    if (isChecked === true) {
      if (checkItems.includes(id) === false) {
        setCheckItems((prev) => [...prev, parseInt(id)])
      }
    } else {
      setCheckItems(checkItems.filter((item) => item !== parseInt(id)))
    }
  }

  useEffect(() => {
    // 파라미터로 넘어온 장바구니 목록에 수량 컬럼을 추가하여 baskets State 생성
    let baskets = []
    bks.map((bk, index, total) => {
      const basket = {
        ...bk,
      }
      baskets.push(basket);
    });
    setBaskets(baskets);
    // 토탈 수량, 금액 State 설정
    setTotalCount(bks.length);
    setTotalPrice(bks.reduce((acc, bk) => {
      return acc + bk.final_price;
    }, 0));
  }, [bks]);

  // 체크박스 선택/해제시 선택된 총수량, 총가격 리랜더링
  useEffect(() => {
    if (baskets) {
      setTotalCount(baskets.length);
      setTotalPrice(baskets.reduce((accumulator, basket) => {
        return accumulator + basket.final_price;
      }, 0));
    }
  }, [checkItems, checkedAll], baskets, setCheckItems);

  // 스크롤 이벤트를 통해 Footer의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);

  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('main');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height), 1300);
      // console.log(`bottom=${window.scrollY}, ${main.getBoundingClientRect().top}, ${main.getBoundingClientRect().height}, ${bottom}`);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="product-basket-list">
      <div className="div-2">
        <RightMenu className="right-menu-instance" href="https://store.gpakorea.com/cs" />
        <SearchArea className="search-area-instance" />
        <MainLogo className="main-logo-instance" href="https://store.gpakorea.com/" />
        <NavMenu className="design-component-instance-node" />
        <LinkTree
          className="link-tree-instance"
          href="https://store.gpakorea.com/"
          href1="https://store.gpakorea.com/"
        />
        <div className="basket-body" id="main">
          <div className="title-area">
            <div className="text-wrapper-17">장바구니</div>
          </div>
          <div className="total-area">
            <div className="link-4" onClick={(e) => {handleAllCheckItem(e)}} style={{cursor:'pointer'}}>
              <div className="text-wrapper-18">전체선택/해제</div>
            </div>
            <div className="link-5" onClick={() => handleSubscribeSelectedItems()} style={{cursor:'pointer'}}>
              <div className="text-wrapper-18">선택 구독</div>
            </div>
            <div className="link-6" onClick={(e) => {handleDeleteSelectedItems(e)}} style={{cursor:'pointer'}}>
              <div className="text-wrapper-18">선택 삭제</div>
            </div>
            <div className="list-2">
              <div className="item-2">
                <div className="div-wrapper-2">
                  <div className="text-wrapper-19">상품수</div>
                </div>
                <div className="div-wrapper-2">
                  <div className="text-wrapper-19">{totalCount.toLocaleString('ko-KR')}개</div>
                </div>
              </div>
              <div className="item-2">
                <div className="div-wrapper-2">
                  <div className="text-wrapper-19">총 결제 금액&nbsp;&nbsp;</div>
                </div>
                <div className="div-wrapper-2">
                  <div className="text-wrapper-19">{totalPrice.toLocaleString('ko-KR')}원</div>
                </div>
              </div>
            </div>
          </div>
          <div className="basket-list">

          {baskets ? baskets.map((basket, index, totcount) => (
            <div className="product">
              <div className="div-panel-heading">
                <div className="label-wrapper">
                  <div className="label">
                    <CheckBox key={basket.id} id={basket.id} name={basket.product_name} 
                      checked={checkItems.includes(basket.id) ? true : false}
                      handleCheckItem={handleCheckItem}
                    />                    </div>
                </div>
                <div className="div-wrapper-3" onClick={(e) => handleSubscribeItem(basket.id, basket.product_id, basket.price_id, e)} style={{cursor:'pointer'}}>
                  <div className="text-wrapper-20">구독</div>
                </div>
                <div className="div-wrapper-3" onClick={(e) => handleDeleteItem(basket.id, e)} style={{cursor:'pointer'}}>
                  <div className="text-wrapper-20">삭제</div>
                </div>
              </div>
              <div className="div-panel-body">
                <div className="div-item-image">
                  <Link to={`/productdetail/${basket.product_id}`} className="link-7">
                    <div className="div-3" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}/media/${basket.thumbmain})`, backgroundSize:'corner'}}/>
                  </Link>
                </div>
                <div className="div-item-data">
                  <div className="table">
                    <div className="div-wrapper-4">
                      <div className="cell">
                        <div className="text-wrapper-21">세부 사양</div>
                      </div>
                    </div>
                    <div className="div-wrapper-4">
                      <div className="data">
                        <div className="strong-2">{basket.product_name}</div>
                        <div className="div-wrapper-5">
                          <div className="text-wrapper-22">{basket.final_price.toLocaleString('ko-KR')}원</div>
                        </div>
                        <div className="div-wrapper-5">
                          <div className="text-wrapper-22">{basket.price_name}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          )) : 
          (<></>)
          }
          </div>
        </div>
        <FooterPanel className="footer-panel-instance" bodyBottom={bodyBottom}/>
        <KakaoService className="design-component-instance-node" />
        <JimmedProducts className="jimmed-products-instance" />
        <ViewedProducts className="viewed-products-instance" />
      </div>
    </div>
  );
};
