// let PROTOCOL = "http"
// let BACKEND_DOMAIN = "127.0.0.1:8000"

let BACKEND_DOMAIN = "data.argus1365.co.kr"
let PROTOCOL = "https"

// let BASEURL = null;
// if (process.env.REACT_API_BACKEND_BASEURL) {
//   // 개발모드
//   BASEURL = process.env.REACT_API_BACKEND_BASEURL;
// } else {
//   // 릴리즈 모드
//   BASEURL = BACKEND_SERVER + "/api/";
// }

// Backend Domain
export const BACKEND_SERVER_DOMAIN = BACKEND_DOMAIN;

// Backend Base Url
export const BACKEND_SERVER_BASEURL = `${PROTOCOL}://${BACKEND_DOMAIN}`;

// 공지사항,자유게시판 페이지당 항목 갯수
export const BODY_COUNT_PER_PAGE = 20;