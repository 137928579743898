import React, {useState, useEffect } from "react";
import { FooterPanel } from "../FooterPanel";
import { JimmedProducts } from "../JimmedProducts";
import { KakaoService } from "../KakaoService";
import { LeftMenu } from "../LeftMenu";
import { LinkTree } from "../LinkTree";
import { MainLogo } from "../MainLogo";
import { NavMenu } from "../NavMenu";
import { RightMenu } from "../RightMenu";
import { SearchArea } from "../SearchArea";
import { ViewedProducts } from "../ViewedProducts";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

const orderTitle = {
  'best': '베스트 상품 순',
  'subscribe': '상품 구독 순',
  'price': '구독 가격 순'
}
export const ProductList = ({ categoryParam, searchWordParam, displayOrderParam, productsParam, onDisplayOrder, onSearchSubmit }) => {
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);

  const [ orderBest, setOrderBest ] = useState(displayOrderParam.split('_')[0] === 'best' ? displayOrderParam : 'best_desc');
  const [ orderSubscribe, setOrderSubscribe ] = useState(displayOrderParam.split('_')[0] === 'subscribe' ? displayOrderParam : 'subscribe_desc');
  const [ orderPrice, setOrderPrice ] = useState(displayOrderParam.split('_')[0] === 'price' ? displayOrderParam : 'price_asc');
  const [ order, setOrder ] = useState(displayOrderParam.length > 0 ? displayOrderParam : 'best_desc');
  const navigate = useNavigate();

  const handleDisplayOrder = (orderParm, e) => {
    e.preventDefault();
    const orderArr = orderParm.split("_");
    if (orderArr[0] === 'price') {
      orderParm = (orderArr.length < 2 || orderArr[1] === 'desc' ) ? 'price_asc' : 'price_desc';
      setOrderPrice(orderParm);
    } else if (orderArr[0] === 'subscribe') {
      orderParm = (orderArr.length < 2 || orderArr[1] === 'asc' ) ? 'subscribe_desc' : 'subscribe_asc';
      setOrderSubscribe(orderParm);
    } else {
      orderParm = (orderArr.length < 2 || orderArr[1] === 'asc' ) ? 'best_desc' : 'best_asc';
      setOrderBest(orderParm);
    }
    onDisplayOrder(orderParm);
    setOrder(orderParm);
  }

  // 스크롤 이벤트를 통해 Footer의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);

  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('main');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="product-list">
      <div className="div-4">
        {/* // .css->"footer-panel-2에서 모든 !important 떼기 */}
        <FooterPanel className="footer-panel-2" bodyBottom={bodyBottom}/> 
        { productsParam ? (
        <div className="product-list-body" id="main">
          <div className="best-tag">
            <div className="arrange-title">{orderTitle[order.split('_')[0]]}</div>
            <div className="best-box" onClick={(e) => {handleDisplayOrder(orderBest, e)}} style={{cursor:'pointer', backgroundColor: (order.split('_')[0] === 'best') ? (order.split('_')[1] === 'asc' ? '#ff0000' : '#0000ff') : '#ffffff' }}>
              <div className="text-wrapper-48" style={{color: (order.split('_')[0] === 'best') ? '#ffffff' : '#000000' }}>B</div>
            </div>
            <div className="view-box" onClick={(e) => {handleDisplayOrder(orderSubscribe, e)}} style={{cursor:'pointer', backgroundColor: (order.split('_')[0] === 'subscribe') ? (order.split('_')[1] === 'asc' ? '#ff0000' : '#0000ff') : '#ffffff' }}>
              <div className="text-wrapper-48" style={{color: (order.split('_')[0] === 'subscribe') ? '#ffffff' : '#000000' }}>V</div>
            </div>
            <div className="price-box" onClick={(e) => {handleDisplayOrder(orderPrice, e)}} style={{cursor:'pointer', backgroundColor: (order.split('_')[0] === 'price') ? (order.split('_')[1] === 'asc' ? '#ff0000' : '#0000ff') : '#ffffff' }}>
              <div className="text-wrapper-48" style={{color: (order.split('_')[0] === 'price') ? '#ffffff' : '#000000' }}>P</div>
            </div>
          </div>
          <div className="best-body">
          {productsParam.map((product, index, total) => (
          <Link to={`/productdetail/${product.id}`} key={index}>

            <div className="best-item">

              <div className="overlap-group-wrapper">
                <div className="button-wrapper" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${product.thumbmain})`, backgroundSize:'corner'}}>
                  <button className="button-4">
                    <div className="button-box-4"></div>
                  </button>
                </div>
              </div>
              <div className="item-info-2">
                <div className="item-title-text-wrapper">
                  <p className="item-title-text-2">{product.product_name.substr(0,40)}</p>
                </div>
                <div className="item-price-text-wrapper">
                  <div className="item-price-text-2">{product.first_price}-&gt;{product.final_price}원</div>
                </div>
                <div className="item-review-2">
                  <div className="item-review-symbol-2"></div>
                  <div className="text-wrapper-49">{product.grade}</div>
                  <div className="item-review-tag-2">|</div>
                  <div className="text-wrapper-49">{product.good_count}개의 평가</div>
                </div>
              </div>
            </div>

          </Link>
          ))}
          </div>
        </div>
        ) : (
          <></>
        )}        

        <JimmedProducts className="design-component-instance-node-5" />
        <ViewedProducts className="design-component-instance-node-5" />
        <LeftMenu className="left-menu-instance" category={(categoryParam && categoryParam.length >= 4) ? categoryParam.substring(0,2) : categoryParam}/>
        <KakaoService className="design-component-instance-node-6" />
        <LinkTree className="link-tree-2" />
        <NavMenu className="design-component-instance-node-6" />
        <MainLogo className="main-logo-2" />
        <SearchArea className="search-area-2" onSearchSubmit={onSearchSubmit}/>
        <RightMenu className="right-menu-2" />
      </div>
    </div>
  );
};
