/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const JimmedProducts = ({
  className,
  divImage = "/img/div-image-2x.png",
  img = "/img/div-image-1.png",
  divImage1 = "/img/div-image-2.png",
}) => {
  return (
    <div className={`jimmed-products ${className}`}>
      <div className="p-title">
        <div className="text-wrapper-4">찜한 상품</div>
      </div>
      <div className="div-box">
        <a className="link" href="https://store.gpakorea.com/item/view/86" rel="noopener noreferrer" target="_blank">
          <img className="div-image" alt="Div image" src={divImage} />
        </a>
        <div className="link">
          <img className="div-image" alt="Div image" src={img} />
        </div>
        <a className="link" href="https://store.gpakorea.com/item/view/1" rel="noopener noreferrer" target="_blank">
          <img className="div-image" alt="Div image" src={divImage1} />
        </a>
      </div>
      <div className="div-pager">
        <div className="div-text-left">
          <div className="symbol-wrapper">
            <div className="symbol"></div>
          </div>
        </div>
        <div className="div-num">
          <div className="text-wrapper-5">1 / 2</div>
        </div>
        <div className="div-text-right">
          <div className="symbol-wrapper">
            <div className="symbol-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

JimmedProducts.propTypes = {
  divImage: PropTypes.string,
  img: PropTypes.string,
  divImage1: PropTypes.string,
};
