import { useParams } from "react-router";
import { ProductDetailContainer } from "../../containers/ProductDetailContainer";
import { ProductListContainer } from "../../containers/ProductListContainer";

export const ProductDetailPage = () => {
  const { id } = useParams();  

  // 기존에 설정된 카테고리 클리어
  useEffect(() => {
    if (category === 'All') {
      setGlobal((global) => ({
        ...global,
        category: 'All',
        category_name: "전체",
        order: 'best_desc',
      }));
    }
  }, []);

  return (
    (!isNaN(+id) === true) ?
    <ProductDetailContainer id={parseInt(id)} /> :
    <ProductListContainer categoryParam={category} orderParam={order} />
  );
}
