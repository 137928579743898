/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ViewedProducts = ({
  className,
  divImage = "/img/div-image-2x.png",
  img = "/img/div-image-1.png",
  divImage1 = "/img/div-image-2.png",
}) => {
  return (
    <div className={`viewed-products ${className}`}>
      <div className="p-title-2">
        <div className="text-wrapper-6">최근본상품</div>
      </div>
      <div className="div-box-2">
        <a
          className="div-image-wrapper"
          href="https://store.gpakorea.com/item/view/86"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img className="img" alt="Div image" src={divImage} />
        </a>
        <div className="div-image-wrapper">
          <img className="img" alt="Div image" src={img} />
        </div>
        <a
          className="div-image-wrapper"
          href="https://store.gpakorea.com/item/view/1"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img className="img" alt="Div image" src={divImage1} />
        </a>
      </div>
      <div className="div-pager-2">
        <div className="link-wrapper">
          <div className="link-2">
            <div className="symbol-3"></div>
          </div>
        </div>
        <div className="div-num-2">
          <div className="text-wrapper-7">1 / 3</div>
        </div>
        <div className="div-text-right-2">
          <div className="link-2">
            <div className="symbol-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewedProducts.propTypes = {
  divImage: PropTypes.string,
  img: PropTypes.string,
  divImage1: PropTypes.string,
};
