import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/auth/auth.context";
import { getCategoryList } from "../../modules/categorys";

export const NavMenu = ({ className }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navigate = useNavigate();
  const [ code, setCode ] = useState("");
  const [ search, setSearch ] = useState("");
  const { data, loading, error } = useSelector(
    (state) => state.categoryReducer.categorylist
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoryList(search));
  }, [dispatch, search]);

  // 주문 조회
  const onCacheDeposit = () => {
    navigate("/cachedepositvirtual");
  };

  const onClick = (paramCode, paramName, e) => {
    e.preventDefault();
    if (paramCode != code ) {

      // 선택된 카테고리 정보 컨텍스트에 저장
      setGlobal((global) => ({
        ...global,
        category: paramCode,
        category_name: paramName
      }));
      navigate(`/productlist?category=${paramCode}`);
      setCode(code);
    }
  }

  if (loading) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  if (!data) return null;

  return (
    <div className={`nav-menu ${className}`}>
      <div className="list">
      {data.map((item, index, total) => (
        <div className="item" key={index} style={{cursor: 'pointer'}}>
          <div className="link-2" onClick={(e)=>{onClick(item.code, item.name, e)}}>
            <div className="text-wrapper-5">{item.name}</div>
          </div>
        </div>

      ))}
      </div>
    </div>
  );
};
