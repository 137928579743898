import { combineReducers } from "redux";
import counter, { counterSaga } from "./counter";
import posts, { postSaga } from "./posts";
import authReducer, { authSaga } from "./auth";
import bulletinReducer, { bulletinSaga } from "./bulletins";
import faqReducer, { faqSaga } from "./faqs";
import inquiryReducer, { inquirySaga } from "./inquirys";
import { all } from "redux-saga/effects";
import productReducer, { productSaga } from "./products";
import categoryReducer, { categorySaga } from "./categorys";

const rootReducer = combineReducers({
  counter,
  posts,
  authReducer,
  bulletinReducer,
  faqReducer,
  inquiryReducer,
  productReducer,
  categoryReducer,
});

export function* rootSaga() {
  yield all([
    counterSaga(),
    postSaga(),
    authSaga(),
    bulletinSaga(),
    faqSaga(),
    inquirySaga(),
    productSaga(),
    categorySaga(),
  ]);
}

export default rootReducer;
