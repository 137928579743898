import {React, useState} from 'react'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import "./style.css";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/auth/auth.context";

const LeftMenuItem = ({ item, depth = 0 }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ code, setCode ] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const icon = collapsed ? <HiChevronUp /> : <HiChevronDown />;
  const navigate = useNavigate();

  function toggleCollapse() {
    setCollapsed(prevValue => !prevValue);
  }

  const onClick = (paramCode, paramName, e) => {
    e.preventDefault();

    // 선택된 카테고리 정보 컨텍스트에 저장
    if (paramCode != code ) {
      setGlobal((global) => ({
        ...global,
        category: paramCode,
        category_name: paramName
      }));
      navigate(`/productlist?category=${paramCode}`);
      // setCollapsed(code);
    }
  }

  if(item.childrens.length > 0) {
    return (
      <div>
        <div className="left-menu-item">
          <div className="text-wrapper-42" style={{cursor: 'pointer'}} onClick={(e) => onClick(item.code, item.name, e)}><p>{item.name}</p>
            <button className="fold_unfold" onClick={toggleCollapse}>
              <div className="symbol-5">{icon}</div>
            </button>
          </div>
        </div>
        <div style={{cursor: 'pointer', overflow: 'hidden',  maxHeight: collapsed ? "100%" : "0"}}>
          {item.childrens.map((child) => (
            <LeftMenuItem item={child} depth={depth + 2} />
          ))}
        </div>
      </div>
    )
  } else {
    return (
      <div className="left-menu-item">
        <div className="text-wrapper-42" style={{cursor: 'pointer', marginLeft: `${(depth*10)}px` }} onClick={(e) => onClick(item.code, item.name, e)}>{item.name}</div>
      </div>
    )
  }
}

export default LeftMenuItem;