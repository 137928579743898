/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const LinkTree = ({ className, afterClassName, afterClassNameOverride, divClassName, href, href1 }) => {
  return (
    <div className={`link-tree ${className}`}>
      <a className="item-link-2" href="https://store.gpakorea.com/" rel="noopener noreferrer" target="_blank">
        <a className="symbol-11" href={href} rel="noopener noreferrer" target="_blank">
          
        </a>
        <div className={`after-2 ${afterClassName}`}>
          <a className="text-wrapper-34" href={href1} rel="noopener noreferrer" target="_blank">
            &gt;
          </a>
        </div>
      </a>
      <div className="item-link-3">
        <div className="text-wrapper-35">카페,커뮤니티</div>
        <div className={`after-3 ${afterClassNameOverride}`}>
          <div className="text-wrapper-34">&gt;</div>
        </div>
      </div>
      <div className="item-link-4">
        <div className="text-wrapper-35">블로그</div>
        <div className={`after-4 ${divClassName}`}>
          <div className="text-wrapper-34">&gt;</div>
        </div>
      </div>
      <div className="item-link-5">
        <div className="text-wrapper-35">전체</div>
      </div>
    </div>
  );
};

LinkTree.propTypes = {
  href: PropTypes.string,
  href1: PropTypes.string,
};
